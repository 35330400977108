export const defaultPGStyleParams = {
  gridStyle: 1,
  galleryLayout: 2,
  isVertical: true,
  imageMargin: 20,
  cubeImages: true,
  cubeType: 'fill',
  cubeRatio: 1,
  slideshowInfoSize: 200,
  gallerySize: 30,
  groupSize: 1,
  titlePlacement: 'SHOW_BELOW',
  allowTitle: true,
  allowDescription: true,
  enableInfiniteScroll: true,
  hoveringBehaviour: 'NEVER_SHOW',
  galleryHorizontalAlign: 'center',
  galleryVerticalAlign: 'center',
  calculateTextBoxHeightMode: 'AUTOMATIC',
  titleDescriptionSpace: 6,
  imageInfoType: 'NO_BACKGROUND',
  overlayAnimation: 'NO_EFFECT',
  imageHoverAnimation: 'NO_EFFECT',
  imageLoadingMode: 'BLUR',
  scrollAnimation: 'NO_EFFECT',
  imageQuality: 90,
  videoPlay: 'hover',
  videoSpeed: '1',
  videoLoop: true,
  allowSocial: false,
};

export const defaultGOGStyleParams = {
  thumbnailSize: 1,
  albumPadding: 20,
  borderWidth: 0,
  borderColor: 4,
  detailsBgColor: 1,
  textAlign: 1,
  cropMode: 1, // face crop
  layout: 4,
  albumClick: 1, // popup
  defaultColorWithOpacity: 'rgba(255, 255, 255, 0.8)',
};

export const gogSpecificGalleryStyleParams = {
  allowDownload: false,
  loveButton: false,
  allowSocial: false,
};

export const gogNewVersionDefaultsStyleParams = {
  colors: {
    itemOpacity: 'rgba(41,41,41, 0.3)',
  },
  numbers: {
    gridStyle: 1,
    overlayAnimation: 1,
    galleryLayout: 2,
    titlePlacement: 0,
    albumClick: 2,
    imageMargin: 20,
    galleryHorizontalAlign: 1,
    thumbnailSize: 120,
  },
  booleans: {
    allowDescription: true,
    previewHover: false,
    isProGalleryGoG: true,
  },
  fonts: {},
  googleFontsCssUrl: '',
  uploadFontFaces: '',
};
